<!-- eslint-disable vue/no-v-html -->
<template>
  <component v-if="customData?.component" :is="customData?.component" :data="customData.props" />

  <div v-else class="max-w-[850px]" v-html="data.html"></div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'
import z from 'zod'

const zCustomData = z.object({
  description: z.string(),
  component: z.string(),
  props: z.record(z.string(), z.any()),
})

export interface IContentHTML {
  id: string
  html?: string
}
interface Props {
  data: IContentHTML
}

const props = defineProps<Props>()

const customData = computed((): z.infer<typeof zCustomData> | null => {
  if (!props?.data?.html) return null

  try {
    const obData = zCustomData.parse(JSON.parse(props?.data?.html))

    const returnData = {
      component: defineAsyncComponent(() => import(`./Custom/${obData.component}.vue`)),
      props: obData.props,
      description: obData.description,
    }

    return returnData
  } catch (e) {
    return null
  }
})
</script>
