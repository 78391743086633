import { default as _91_46_46_46slug_936jkaE6IT4XMeta } from "/opt/render/project/src/apps/refresh/pages/[...slug].vue?macro=true";
import { default as search1XTvFeU5TyMeta } from "/opt/render/project/src/apps/refresh/pages/search.vue?macro=true";
import { default as rejectIhWLIRnGNKMeta } from "/opt/render/project/src/apps/refresh/pages/swish/reject.vue?macro=true";
import { default as success6tcPTw18poMeta } from "/opt/render/project/src/apps/refresh/pages/swish/success.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/render/project/src/apps/refresh/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/opt/render/project/src/apps/refresh/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "swish-reject",
    path: "/swish/reject",
    component: () => import("/opt/render/project/src/apps/refresh/pages/swish/reject.vue").then(m => m.default || m)
  },
  {
    name: "swish-success",
    path: "/swish/success",
    component: () => import("/opt/render/project/src/apps/refresh/pages/swish/success.vue").then(m => m.default || m)
  }
]