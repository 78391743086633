import { z } from 'zod'
import { withQuery, joinURL } from 'ufo'

/**
 * Url param zod schemas
 */

export const zQueryParams = z.object({
  // language override
  lang: z.enum(['sv', 'en', 'es', 'ua', '']).nullish().default(''),

  // Campaign overrides
  campaignCode: z.coerce.string().nullish().default(''), // campaignCode override (salesforce or Dynamics)
  askCode: z.coerce.string().nullish().default(''), // second ask campaignCode override (salesforce or Dynamics)

  // Tools
  debug: z.coerce.boolean().nullish().optional().default(false), // Displays debug info, urls, forces sum to minimum amount

  // Form data presets
  sum: z.coerce.number().nullish().optional(),
  ssn: z.coerce.string().nullish().optional().default(''),
  birthDate: z.coerce.string().nullish().optional().default(''),
  street: z.coerce.string().nullish().optional().default(''),
  postcode: z.coerce.string().nullish().optional().default(''),
  city: z.coerce.string().nullish().optional().default(''),
  countryCode: z.coerce.string().nullish().optional().default(''),
  mobile: z.coerce.string().nullish().optional().default(''),
  email: z.coerce.string().nullish().optional().default(''),
  fname: z.coerce.string().nullish().optional().default(''),
  lname: z.coerce.string().nullish().optional().default(''),

  // Google style tracking params -- these are actively passed on
  utm_source: z.coerce.string().nullish().optional().default(''),
  utm_medium: z.coerce.string().nullish().optional().default(''),
  utm_campaign: z.coerce.string().nullish().optional().default(''),
  utm_term: z.coerce.string().nullish().optional().default(''),
  utm_content: z.coerce.string().nullish().optional().default(''),
  utm_id: z.coerce.string().nullish().optional().default(''),
})

export const zFormUrls = zQueryParams.extend({
  step: z.coerce.number().nullish().optional().default(0),
})

export const zResultUrls = zQueryParams.extend({
  // Tracking params
  type: z.enum([
    'petition',
    'protest',
    'company-single',
    'company-monthly',
    'donation-single',
    'donation-gifted',
    'donation-memorial',
    'donation-monthly',
    'membership-annual',
    'membership-annual-gifted',
    'membership-monthly',
    'rdk',
    'invoice', // including renewal
    'event',
  ]), // interaction type
  slug: z.coerce.string().nullish().default(''), // Contentful form reference slug
  debug: z.string().optional(), // Displays debug info, urls, forces sum to minimum amount

  // Common params
  wsId: z.coerce.string().nullish().default(''), // Salesforce websafe id
  whId: z.coerce.string().nullish().default(''), // Dynamics websafe id (hashId)
  sysId: z.coerce.string().nullish().default(''), // Contentful result page sysId to load
  state: z.enum(['ok', 'aborted', 'postal']).optional().default('ok'), // Result state

  // Djurens Rätt params
  partId: z.coerce.string().nullish().default(''), // Dynamics user partId for Djurens Rätt
  transactionId: z.coerce.string().nullish().default(''), // Dynamics transaction id

  // 1882 Params
  webOrderId: z.coerce.string().nullish().default(''), // Salesforce web order id
  customerId: z.coerce.string().nullish().default(''), // Stripe customer id
  invoiceId: z.coerce.string().nullish().default(''), // Stripe invoice id
})

function removeEmpty(obj: { [key: string]: any }): { [key: string]: any } {
  return Object.entries(obj).reduce((a, [k, v]) => (v === '' ? a : { ...a, [k]: v }), {})
}

function removeNone(obj: { [key: string]: any }): { [key: string]: any } {
  return Object.entries(obj).reduce((a, [k, v]) => (v === 'NONE' ? a : { ...a, [k]: v }), {})
}

export const buildResultUrl = (baseUrl: string, params: z.input<typeof zResultUrls>): string => {
  const parsedParams = zResultUrls.parse(params)
  let onlyDefined = removeNone(removeEmpty(parsedParams))
  delete onlyDefined['debug'] // never pass on debug
  const relative = withQuery('/thank-you', onlyDefined)
  return baseUrl ? joinURL(baseUrl, relative) : relative
}
