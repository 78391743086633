<!-- eslint-disable vue/no-v-html -->
<template>
  <!-- Input -->
  <WebformElementWrapper
    v-if="inputFields.includes(elementWithState['#type']) && elementWithState['#access']"
    :description="elementWithState['#description']"
  >
    <Input
      v-model.trim="inputValue"
      :name="iid"
      :pattern="elementWithState['#pattern']"
      :pattern-error="elementWithState['#pattern_error']"
      :label="elementWithState['#title']"
      :type="elementWithState['#type']"
      :disabled="elementWithState['#disabled'] || disabled"
      :hidden="!elementWithState['#access']"
      :read-only="elementWithState['#readonly']"
      :required="elementWithState['#required']"
      style-input="border border-blue-dark focus:border-blue-dark w-full focus-visible:border-blue-dark outline-0"
      :v$="v$?.[iid]"
      @set-data="setData"
    />
  </WebformElementWrapper>

  <!-- hidden field-->
  <HiddenField
    v-if="element['#type'] === 'hidden'"
    :id="iid"
    v-model.trim="inputValue"
    :name="iid"
    :default-value="queryDefault || element['#default_value']"
    @set-data="setData"
  />

  <!-- fieldset -->
  <fieldset v-if="element['#type'] === 'fieldset'" :id="iid" :name="iid">
    <legend class="mb-2 mt-6 font-bold">{{ element['#title'] }}</legend>
    <p>{{ element['#description'] }}</p>
    <div class="flex flex-col space-y-4">
      <WebformElement
        v-for="item in children"
        :key="item[0]"
        v-model.trim="formField[item[0]]"
        :iid="item[0]"
        :element="item[1]"
        :is-loading="isLoading"
        :form-id="formId"
        :disabled="disabled"
        :v$="v$"
      />
    </div>
  </fieldset>

  <!-- Textarea -->
  <WebformElementWrapper
    v-if="elementWithState['#type'] === 'textarea' && elementWithState['#access']"
    :description="elementWithState['#description']"
  >
    <Textarea
      v-model.trim="inputValue"
      style-input="border !border-blue-dark focus:border-blue-dark shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)]"
      :name="iid"
      :form-id="formId"
      :label="elementWithState['#title']"
      :disabled="elementWithState['#disabled'] || disabled"
      :hidden="!elementWithState['#access']"
      :read-only="elementWithState['#readonly']"
      :required="elementWithState['#required']"
      @set-data="setData"
    />
  </WebformElementWrapper>

  <!-- Radio Buttons -->
  <WebformElementWrapper
    v-if="element['#type'] === 'radios'"
    :description="element['#description']"
  >
    <div class="flex flex-col space-y-2">
      <div class="font-text font-sans text-black lg:text-lg">
        {{ element['#title'] }}
        <span v-if="element['#required']">*</span>
      </div>
      <RadioButton
        v-for="(item, index) in Object.entries(element['#options'])"
        :key="index"
        v-model="inputValue"
        :name="iid"
        :value="item[0]"
        :required="element['#required']"
        :disabled="element['#disabled'] || disabled"
        @set-data="setData"
      >
        {{ item[1] }}
      </RadioButton>
    </div>
  </WebformElementWrapper>

  <!-- checkbox -->
  <WebformElementWrapper
    v-if="elementWithState['#type'] === 'checkbox' && elementWithState['#access']"
    :description="elementWithState['#description']"
  >
    <div class="mt-4">
      <Checkbox
        v-model="inputValue"
        :form-id="formId"
        :name="iid"
        :label="elementWithState['#title']"
        :disabled="elementWithState['#disabled'] || disabled"
        :hidden="!elementWithState['#access']"
        :read-only="elementWithState['#readonly']"
        :required="elementWithState['#required']"
        @set-data="setData"
      />
    </div>
  </WebformElementWrapper>

  <!-- multi checkbox -->
  <WebformElementWrapper
    v-if="elementWithState['#type'] === 'checkboxes' && elementWithState['#access']"
    :description="elementWithState['#description']"
  >
    <CheckboxesMulti
      :id="iid"
      v-model="inputValue"
      :form-id="formId"
      :title="elementWithState['#title']"
      :options="elementWithState['#options']"
      :required="elementWithState['#required']"
      :disabled="elementWithState['#disabled'] || disabled"
      @set-data="setData"
    />
  </WebformElementWrapper>

  <!-- select field -->
  <WebformElementWrapper
    v-if="
      elementWithState['#type'] === 'select' &&
      elementWithState['#access'] &&
      !['country_codes', 'country_names'].includes(elementWithState['#options'])
    "
    :description="elementWithState['#description']"
  >
    <Select
      :id="iid"
      v-model="inputValue"
      :name="iid"
      :title="elementWithState['#title']"
      :options="elementWithState['#options']"
      :required="elementWithState['#required']"
      :disabled="elementWithState['#disabled'] || disabled"
      :hidden="!elementWithState['#access']"
      :read-only="elementWithState['#readonly']"
      @set-data="setData"
    />
  </WebformElementWrapper>

  <!-- select field -->
  <WebformElementWrapper
    v-if="
      elementWithState['#type'] === 'select' &&
      elementWithState['#access'] &&
      ['country_codes', 'country_names'].includes(elementWithState['#options'])
    "
    :description="elementWithState['#description']"
  >
    <MultiSelect
      :id="iid"
      v-model="inputValue"
      :name="iid"
      :title="elementWithState['#title']"
      :options="countryOptions[elementWithState['#options']]"
      :required="elementWithState['#required']"
      :disabled="elementWithState['#disabled'] || disabled"
      :hidden="!elementWithState['#access']"
      :read-only="elementWithState['#readonly']"
      @set-data="setData"
    />
  </WebformElementWrapper>

  <!-- select other -->

  <WebformElementWrapper
    v-if="elementWithState['#type'] === 'webform_select_other' && elementWithState['#access']"
    :description="elementWithState['#description']"
  >
    <SelectOther
      :id="iid"
      v-model.trim="inputValue"
      :title="elementWithState['#title']"
      :name="iid"
      :options="elementWithState['#options']"
      :other-label="elementWithState['#other__option_label']"
      :other-placeholder="elementWithState['#other__placeholder']"
      :required="elementWithState['#required']"
      :hidden="!elementWithState['#access']"
      :disabled="elementWithState['#disabled'] || disabled"
      @set-data="setData"
    />
  </WebformElementWrapper>

  <!-- radio other -->
  <WebformElementWrapper
    v-if="elementWithState['#type'] === 'webform_radios_other' && elementWithState['#access']"
    :description="elementWithState['#description']"
  >
    <RadioButtonOther
      :id="iid"
      v-model.trim="inputValue"
      :title="elementWithState['#title']"
      :name="iid"
      :options="elementWithState['#options']"
      :other-label="elementWithState['#other__option_label']"
      :other-placeholder="elementWithState['#other__placeholder']"
      :required="elementWithState['#required']"
      :hidden="!elementWithState['#access']"
      :disabled="elementWithState['#disabled'] || disabled"
      @set-data="setData"
    />
  </WebformElementWrapper>

  <!-- file fields -->
  <WebformElementWrapper
    v-if="fileField.includes(elementWithState['#type']) && elementWithState['#access']"
    :description="elementWithState['#description']"
  >
    <FileField
      :id="iid"
      v-model="inputValue"
      :title="elementWithState['#title']"
      :required="elementWithState['#required']"
      :form-id="formId"
      :type="elementWithState['#type']"
      :hidden="!elementWithState['#access']"
      :disabled="elementWithState['#disabled'] || disabled"
    />
  </WebformElementWrapper>

  <!-- Flexbox layout -->
  <div
    v-if="element['#type'] === 'webform_flexbox'"
    :id="iid"
    :name="iid"
    class="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0"
  >
    <WebformElement
      v-for="item in children"
      :key="item[0]"
      v-model="formField[item[0]]"
      :iid="item[0]"
      :element="item[1]"
      :is-loading="isLoading"
      :form-id="formId"
      :disabled="disabled"
      :v$="v$"
    />
  </div>

  <!-- Submit button -->
  <Button
    v-if="element['#type'] === 'webform_actions' && element['#submit__label']"
    :title="element['#submit__label']"
    :disabled="isLoading || disabled"
    :is-loading="isLoading"
    type-button="submit"
    class="!mt-8 w-full px-8 lg:w-auto"
    :class="formId + '-submit'"
  />

  <!-- Text block -->
  <div
    v-if="element['#type'] === 'processed_text'"
    class="prose w-full font-sans text-xs leading-normal text-black lg:prose-lg prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:text-sm lg:leading-relaxed [&_p]:text-xs lg:[&_p]:text-sm"
    v-html="element['#text']"
  ></div>
</template>

<script setup lang="ts">
import { ref, watch, reactive, computed } from 'vue'
import { formData, useForm, inputFields } from 'refresh-common'
import { Input, Button } from 'refresh-ui'
import RadioButton from '@/components/atoms/RadioButton.vue'
import Textarea from '@/components/molecules/WebformsComponent/Textarea.vue'
import Checkbox from '@/components/molecules/WebformsComponent/Checkbox.vue'
import MultiSelect from '@/components/molecules/WebformsComponent/MultiSelect.vue'
import WebformElementWrapper from '@/components/molecules/WebformsComponent/WebformElementWrapper.vue'
import HiddenField from '@/components/molecules/WebformsComponent/HiddenField.vue'
import CheckboxesMulti from '@/components/molecules/WebformsComponent/CheckboxesMulti.vue'
import Select from '@/components/molecules/WebformsComponent/Select.vue'
import RadioButtonOther from '@/components/molecules/WebformsComponent/RadioButtonOther.vue'
import FileField from '@/components/molecules/WebformsComponent/FileField.vue'
import SelectOther from '@/components/molecules/WebformsComponent/SelectOther.vue'
import { useCountries } from '@/composables/useCountries'
import { useRuntimeConfig } from '#app'

const { cmsBasePath } = useRuntimeConfig().public

const { siteLang } = useRuntimeConfig().public as { siteLang: lang }

const { getStatesForData, updateDataForm } = useForm({ cmsBasePath })

const props = defineProps({
  element: Object,
  iid: String,
  modelValue: [String, Boolean, Number, Object],
  isLoading: Boolean,
  formId: String,
  disabled: Boolean,
  v$: Object,
})

const { countryCodes, countryNames } = useCountries(siteLang)

const countryOptions = {
  country_codes: countryCodes,
  country_names: countryNames,
}

const route = useRoute()
const checkKeyExists = (obj, key) => key in obj
const queryDefault =
  typeof route.query === 'object' && props.iid && checkKeyExists(route.query, props.iid)
    ? route.query[props.iid]
    : ''

const state = ref(getStatesForData(props.element['#states'], formData.data[props.formId]))

const inputValue = queryDefault ? ref(queryDefault) : ref(props.modelValue)

const formField = reactive({})

const fileField = ['managed_file', 'webform_image_file', 'webform_document_file']

const elementWithState = computed(() => ({ ...props.element, ...state.value }))

const emit = defineEmits(['update:modelValue'])

const elements = Object.entries(props.element)

const children = elements.filter(
  item => item[0] && typeof item[0] === 'string' && item[0].charAt(0) !== '#',
)

const setData = ({ key, value }) => {
  // console.log('WebformElement setdata', { key, value });
  const data = { ...formData.data[props.formId], [key]: value }
  // console.log('updateDataForm', { formId: props.formId, data });
  updateDataForm(props.formId, data)
  // Note: this was added to fix reactivity when values are modified programmatically instead of by the user.
  emit('update:modelValue', value)
}

watch(formData, () => {
  state.value = getStatesForData(props.element['#states'], formData.data[props.formId])
})

watch(formField, () => {
  emit('update:modelValue', formField)
})

watch(inputValue, () => {
  emit('update:modelValue', inputValue.value)
})
</script>
