<template>
  <div>
    <InfoFormBlock
      :id="encodeHash($t('petitionForm.petitionAnchor') + '-' + props.data.formId)"
      :data="componentData"
    >
      <!-- @vue-ignore -->
      <PetitionCounter
        :is-closed="!!document?.closed"
        :is-protest="isProtest"
        :form-id="document.dbOverride || props.data.formId"
        class="mb-4 lg:mb-10"
      />
      <FormPetition
        v-if="!document?.closed"
        :petition-id="props.data.formId"
        :db-override="document.dbOverride || ''"
        :is-protest="isProtest"
        :endpointId="document?.endpoint?.sys?.id"
        :campaignCode="document?.actionCode"
      />
      <div
        class="prose font-sans text-base leading-[21px] lg:prose-lg prose-p:text-lg prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:leading-[26px] lg:prose-p:text-xl"
        v-if="document.closed && document.closedMessage"
      >
        <RichTextRenderer v-if="closedMessage" :document="closedMessage" />
      </div>
    </InfoFormBlock>
    <PetitionMotivations
      :form-id="props.data.formId"
      :is-protest="isProtest"
      class="mb-4 lg:mb-10"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ActionDocument } from '../../../types/contentfulCms'

// @ts-ignore
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import FormPetition from '@/components/molecules/FormPetition.vue'
import InfoFormBlock from './InfoFormBlock.vue'
import PetitionCounter from '@/components/molecules/PetitionCounter.vue'
import PetitionMotivations from '@/components/molecules/PetitionMotivations.vue'
import { useAsyncData, useNuxtApp, useRuntimeConfig } from '#app'
import { computed } from 'vue'
import { buildUrl } from 'cloudinary-build-url'
import { STORAGE_TYPES } from '@cld-apis/utils'
import { useI18n } from '#imports'
import { marked } from 'marked'
import { encodeHash } from 'ufo'

const { $client } = useNuxtApp()
const { t } = useI18n()
const config = useRuntimeConfig()

export interface Props {
  data: {
    formId: string
  }
}

const props = withDefaults(defineProps<Props>(), {
  data: () => ({ formId: '' }),
})

// TODO Fix typing
// @ts-ignore
const { data: dataContentful } = await useAsyncData(`petition--${props.data.formId}`, () =>
  // @ts-ignore
  $client.getEntries({
    content_type: 'action',
    'fields.slug': props.data.formId,
    limit: 1,
  }),
)
// @ts-ignore
const document: ActionDocument = dataContentful?.value?.items[0]?.fields || {}

const closedMessage = document?.closedMessage || ''

const isProtest = document?.mailTemplate ? true : false

const cloudinaryImg = buildUrl('https:' + document?.image?.fields?.file?.url, {
  cloud: {
    cloudName: config.public.cloudname,
    storageType: STORAGE_TYPES.FETCH,
  },
  transformations: {
    format: 'webp',
    resize: {
      type: 'fill',
      width: 1200,
      height: 600,
    },
  },
})

const componentData = computed(() => ({
  title: document?.name || '',
  subtitle: isProtest ? t('petitionForm.protestLabel') : t('petitionForm.petitionLabel'),
  copy: document?.excerpt ? marked(document.excerpt) : '',
  image: {
    url: cloudinaryImg,
    type: 'url',
    alt: document?.name || '',
  },
}))
</script>
