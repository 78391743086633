import payload_plugin_ssyGoCmsFt from "/opt/render/project/src/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@11.11.1_firebase-functions@4.9.0__yhfdjay6w3yv7nnowitixpuhzi/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_YaM8QG5hi8 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7pgH2qx7kY from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OPi7EISKUk from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_4ZUlz0E41Z from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_BfVjXMze6X from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kDRsKoOEXn from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_BRdQvj6b7Z from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_qPjjTRhYGF from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/refresh/.nuxt/components.plugin.mjs";
import prefetch_client_1JsTI9A4f4 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ndyCNbA6y4 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.21.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_6RaPwgxxy0 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_vue@3.4.38/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import i18n_uqewXG34do from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.1.0_rollup@4.21.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_0Ir0ZU8jHB from "/opt/render/project/src/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@11.11.1_firebase-functions@4.9.0__yhfdjay6w3yv7nnowitixpuhzi/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_plugin_8cq4FnzXKb from "/opt/render/project/src/apps/refresh/.nuxt/vuefire-plugin.mjs";
import contentful_bOzdIRmqaX from "/opt/render/project/src/apps/refresh/plugins/contentful.js";
import rollbar_XnPIYyOzed from "/opt/render/project/src/apps/refresh/plugins/rollbar.ts";
import socialShare_gA6PfhsR4E from "/opt/render/project/src/apps/refresh/plugins/socialShare.js";
export default [
  payload_plugin_ssyGoCmsFt,
  revive_payload_client_YaM8QG5hi8,
  unhead_7pgH2qx7kY,
  router_OPi7EISKUk,
  _0_siteConfig_4ZUlz0E41Z,
  payload_client_BfVjXMze6X,
  navigation_repaint_client_kDRsKoOEXn,
  check_outdated_build_client_BRdQvj6b7Z,
  chunk_reload_client_qPjjTRhYGF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1JsTI9A4f4,
  plugin_ndyCNbA6y4,
  plugin_6RaPwgxxy0,
  i18n_uqewXG34do,
  plugin_client_0Ir0ZU8jHB,
  vuefire_plugin_8cq4FnzXKb,
  contentful_bOzdIRmqaX,
  rollbar_XnPIYyOzed,
  socialShare_gA6PfhsR4E
]