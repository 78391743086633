import { getCategory } from './getCategory'
import { getEntities } from './getEntities'
import { getLink } from './getLink'
import { getLinks } from './getLinks'
import { getVideo } from './getVideo'
import { getImageCloudinary } from './getImageCloudinary'
import { getImageOriginal } from './getImageOriginal'
import { proxyOriginalImage } from './proxyOriginalImage'
import { getMedia } from './getMedia'
import { getForm } from './getForm'
import { getImageCloudinaryImg } from './getImageCloudinaryImg'

import { store } from '.'

import {
  BIG_ARGUMENT_BLOCK,
  BIG_CTA_BLOCK,
  CARD_ROW_BLOCK,
  COMPACT_NEWS_BLOCK,
  CTA_BANNER_BLOCK,
  FACTS_BLOCK,
  FAQ_BLOCK,
  IMAGE_BLOCK,
  IMAGE_TEXT_BLOCK,
  INFO_FORM_BLOCK,
  INTRO_BLOCK,
  LIST_BLOCK,
  NEWS_CARD_SLIDER_BLOCK,
  ON_THIS_PAGE_BLOCK,
  PODCAST_BLOCK,
  SHORT_ZEBRA_BLOCK,
  TABS_BLOCK,
  TALL_CARD_SLIDER_BLOCK,
  TESTIMONIAL_BLOCK,
  TIMELINE_SLIDER_BLOCK,
  TINY_SLIDER_BLOCK,
  VIDEO_BLOCK,
  CONTENT_HTML_BLOCK,
  CONTENT_TEXT_BLOCK,
  SEARCH_STRINGS_BLOCK,
  LIST_AUTO_BLOCK,
  PETITION_BLOCK,
  EXPANDABLE_BLOCK,
  TITLE_TEXT_BLOCK,
  SMALL_IMAGE_GRID_BLOCK,
} from '../../utils/constants'

const blocksForOnThisPage = [
  BIG_ARGUMENT_BLOCK,
  CARD_ROW_BLOCK,
  COMPACT_NEWS_BLOCK,
  FACTS_BLOCK,
  FAQ_BLOCK,
  IMAGE_BLOCK,
  IMAGE_TEXT_BLOCK,
  INFO_FORM_BLOCK,
  INTRO_BLOCK,
  LIST_BLOCK,
  NEWS_CARD_SLIDER_BLOCK,
  PODCAST_BLOCK,
  SHORT_ZEBRA_BLOCK,
  TABS_BLOCK,
  TALL_CARD_SLIDER_BLOCK,
  TESTIMONIAL_BLOCK,
  TIMELINE_SLIDER_BLOCK,
  TINY_SLIDER_BLOCK,
  VIDEO_BLOCK,
  CONTENT_HTML_BLOCK,
  CONTENT_TEXT_BLOCK,
  SEARCH_STRINGS_BLOCK,
  LIST_AUTO_BLOCK,
  PETITION_BLOCK,
  TITLE_TEXT_BLOCK,
  SMALL_IMAGE_GRID_BLOCK,
]

export const getDataComponent = (
  element: any,
  config: { cmsBasePath: string; cloudname: string },
  t: any,
) => {
  if (element.type === INTRO_BLOCK) {
    try {
      return {
        type: INTRO_BLOCK,
        id: element?.id,
        title: element.attributes?.field_title,
        subtitle: element.attributes?.field_subtitle,
        color: element.attributes?.field_color,
        copy: element.attributes?.field_wysiwyg?.processed,
        body: element.attributes?.field_body?.processed,
        position: element.attributes.field_align,
        link: getLink(element?.relationships?.field_link?.data),
      }
    } catch (error) {
      console.warn('INTRO_BLOCK', error)
    }
  }
  if (element.type === CTA_BANNER_BLOCK) {
    try {
      return {
        type: CTA_BANNER_BLOCK,
        id: element?.id,
        title: element.attributes?.field_title,
        subtitle: element.attributes?.field_subtitle,
        copy: element.attributes?.field_wysiwyg?.processed,
        link: getLink(element?.relationships?.field_link?.data),
      }
    } catch (error) {
      console.warn('CTA_BANNER_BLOCK', error)
    }
  }
  if (element.type === VIDEO_BLOCK) {
    try {
      return {
        type: VIDEO_BLOCK,
        id: element?.id,
        video: getVideo(element?.relationships?.field_media?.data),
        verticalVideo: getVideo(element?.relationships?.field_media_vertical?.data),
      }
    } catch (error) {
      console.warn('VIDEO_BLOCK', error)
    }
  }

  if (element.type === TALL_CARD_SLIDER_BLOCK) {
    try {
      return {
        type: TALL_CARD_SLIDER_BLOCK,
        id: element?.id,
        title: element.attributes?.field_title,
        subtitle: element.attributes?.field_subtitle,
        cards: getEntities(element.relationships?.field_entities.data).map((entity: any) => ({
          title: entity.attributes.title,
          subtitle: entity.attributes?.field_subtitle,
          copy: entity?.attributes?.field_wysiwyg?.processed,
          link: getLink(entity?.relationships?.field_link?.data),
          image: getImageCloudinary(
            entity?.relationships?.field_media?.data,
            {
              transformations: {
                format: 'webp',
                resize: {
                  type: 'fill',
                  height: 600,
                },
              },
            },
            {},
            { cloudname: config.cloudname },
          ),
        })),
        link: getLink(element?.relationships?.field_link?.data),
        mode: element?.attributes?.field_slider_mode,
      }
    } catch (error) {
      console.warn('TALL_CARD_SLIDER_BLOCK', error)
    }
  }

  if (element.type === FACTS_BLOCK) {
    try {
      return {
        type: FACTS_BLOCK,
        id: element?.id,
        subtitle: element.attributes?.field_title,
        cards: getEntities(element.relationships?.field_reference.data).map((entity: any) => ({
          title: entity.attributes?.field_title,
          copy: entity?.attributes?.field_wysiwyg?.processed,
        })),
        link: getLink(element?.relationships?.field_link?.data),
      }
    } catch (error) {
      console.warn('FACTS_BLOCK', error)
    }
  }

  if (element.type === INFO_FORM_BLOCK) {
    try {
      return {
        type: INFO_FORM_BLOCK,
        id: element?.id,
        title: element.attributes?.field_title,
        subtitle: element.attributes?.field_subtitle,
        copy: element.attributes?.field_wysiwyg?.processed,
        typeForm: element.attributes?.field_desktop_variant,
        image: getImageCloudinaryImg(element?.relationships?.field_media?.data),
        form: getForm(element?.relationships?.field_webform?.data),
      }
    } catch (error) {
      console.warn('INFO_FORM_BLOCK', error)
    }
  }

  if (element.type === LIST_BLOCK) {
    try {
      return {
        type: LIST_BLOCK,
        id: element?.id,
        cards: getEntities(element.relationships?.field_entities.data).map((entity: any) => ({
          title: entity.attributes.title,
          copy: entity?.attributes?.field_wysiwyg?.processed,
          link:
            entity?.attributes?.field_link_type === 'ext'
              ? {
                  title: entity?.attributes?.field_link_title,
                  url: entity?.attributes?.field_link_ext?.uri || '',
                  type: entity?.attributes?.field_link_type,
                }
              : {
                  title: entity?.attributes?.field_link_title,
                  url: entity?.attributes?.computed_link_int,
                  type: entity?.attributes?.field_link_type,
                },
          test: entity?.relationships?.field_link_int?.data,
          image: getImageCloudinary(
            entity.relationships?.field_media?.data,
            {
              transformations: {
                format: 'webp',
                resize: {
                  type: 'fill',
                  height: 600,
                },
              },
            },
            {},
            { cloudname: config.cloudname },
          ),
        })),
        link: getLink(element?.relationships?.field_link?.data),
      }
    } catch (error) {
      console.warn('LIST_BLOCK', error)
    }
  }

  if (element.type === SHORT_ZEBRA_BLOCK) {
    try {
      return {
        type: SHORT_ZEBRA_BLOCK,
        id: element?.id,
        cards: getEntities(element.relationships?.field_reference.data).map((entity: any) => ({
          title: entity.attributes?.field_title,
          subtitle: entity.attributes?.field_subtitle,
          copy: entity?.attributes?.field_wysiwyg?.processed,
          image: getImageCloudinary(
            entity.relationships?.field_media?.data,
            {
              transformations: {
                format: 'webp',
                resize: {
                  type: 'fill',
                  height: 1024,
                },
              },
            },
            {},
            { cloudname: config.cloudname },
          ),
        })),
      }
    } catch (error) {
      console.warn('SHORT_ZEBRA_BLOCK', error)
    }
  }

  if (element.type === IMAGE_TEXT_BLOCK) {
    try {
      return {
        type: IMAGE_TEXT_BLOCK,
        id: element?.id,
        cards: getEntities(element.relationships?.field_reference.data).map((entity: any) => ({
          mediaType: entity.attributes.field_media_type,
          title: entity.attributes?.field_title,
          subtitle: entity.attributes?.field_subtitle,
          copy: entity?.attributes?.field_wysiwyg?.processed,
          video: getVideo(entity.relationships?.field_media_video?.data),
          image: getImageCloudinary(
            entity.relationships?.field_media?.data,
            {
              transformations: {
                format: 'webp',
                resize: {
                  type: 'fill',
                  width: 800,
                  height: 600,
                },
              },
            },
            {},
            { cloudname: config.cloudname },
          ),
          link: getLink(entity.relationships?.field_link?.data),
          rowStyle: entity?.attributes?.field_row_style,
        })),
      }
    } catch (error) {
      console.warn('IMAGE_TEXT_BLOCK', error)
    }
  }

  if (element.type === TIMELINE_SLIDER_BLOCK) {
    try {
      return {
        type: TIMELINE_SLIDER_BLOCK,
        id: element?.id,
        title: element.attributes?.field_title,
        subtitle: element.attributes?.field_subtitle,
        cards: getEntities(element.relationships?.field_entities.data).map((entity: any) => ({
          timeline: entity.attributes.field_header,
          title: entity.attributes.title,
          copy: entity?.attributes?.field_wysiwyg?.processed,
          link: getLink(entity.relationships?.field_link?.data),
          image: getImageCloudinaryImg(entity.relationships?.field_media?.data),
        })),
      }
    } catch (error) {
      console.warn('TIMELINE_SLIDER_BLOCK', error)
    }
  }

  if (element.type === CARD_ROW_BLOCK) {
    try {
      return {
        type: CARD_ROW_BLOCK,
        id: element?.id,
        cards: getEntities(element.relationships?.field_reference.data).map((entity: any) => ({
          title: entity.attributes?.field_title,
          copy: entity?.attributes?.field_wysiwyg?.processed,
          image: getImageCloudinary(
            entity.relationships?.field_media?.data,
            {
              transformations: {
                format: 'webp',
                resize: {
                  type: 'fill',
                  height: 600,
                },
              },
            },
            {},
            { cloudname: config.cloudname },
          ),
          link: getLink(entity.relationships?.field_link?.data),
        })),
      }
    } catch (error) {
      console.warn('CARD_ROW_BLOCK', error)
    }
  }

  if (element.type === IMAGE_BLOCK) {
    try {
      return {
        type: IMAGE_BLOCK,
        id: element?.id,
        desktopSize: element?.attributes?.field_desktop_size,
        originalAspect: element?.attributes?.field_original_aspect,
        byline: element?.attributes?.field_byline,
        image: getImageCloudinaryImg(element.relationships?.field_media?.data),
      }
    } catch (error) {
      console.warn('IMAGE_BLOCK', error)
    }
  }

  if (element.type === NEWS_CARD_SLIDER_BLOCK) {
    try {
      const cardsData = JSON.parse(element.attributes.computed_list) || []

      const data = {
        type: NEWS_CARD_SLIDER_BLOCK,
        id: element?.id,
        title: element.attributes?.field_title,
        subtitle: element.attributes?.field_subtitle,
        copy: element?.attributes?.field_wysiwyg?.processed,
        link: getLink(element?.relationships?.field_link?.data),
        cards: cardsData.map((card: any) => ({
          id: card?.data?.id,
          title: card?.data?.attributes?.title,
          image: card?.data?.relationships?.field_node_media?.data
            ? getImageCloudinary(
                card?.data?.relationships?.field_node_media?.data,
                {
                  transformations: {
                    format: 'webp',
                    resize: {
                      type: 'fill',
                      height: 700,
                      width: 600,
                    },
                  },
                },
                card?.included,
                { cloudname: config.cloudname },
              )
            : proxyOriginalImage({
                url: getImageOriginal(
                  card?.data?.relationships?.field_image?.data,
                  card?.included,
                  config.cmsBasePath,
                ),
                cloudinaryOptions: {
                  transformations: {
                    format: 'webp',
                    resize: {
                      type: 'fill',
                      height: 700,
                      width: 600,
                    },
                  },
                },
                config: { cloudname: config.cloudname, cmsBasePath: config.cmsBasePath },
              }) /* getImageOriginal(
              card?.data?.relationships?.field_image?.data,
              card?.included,
              config.cmsBasePath,
            )*/,
          category: getCategory(card?.data?.relationships, card?.included),
          link: card?.data?.attributes?.path?.alias,
        })),
      }
      return data
    } catch (error) {
      console.warn('NEWS_CARD_SLIDER_BLOCK', error)
    }
  }

  if (element.type === TINY_SLIDER_BLOCK) {
    try {
      return {
        type: TINY_SLIDER_BLOCK,
        id: element?.id,
        title: element.attributes?.field_title,
        subtitle: element.attributes?.field_subtitle,
        copy: element.attributes?.field_wysiwyg?.processed,
        link: getLink(element?.relationships?.field_link?.data),
        image: getImageCloudinaryImg(element.relationships?.field_media?.data),
        cards: getEntities(element.relationships?.field_entities.data).map((entity: any) => ({
          title: entity.attributes.title,
          subtitle: entity.attributes?.field_subtitle,
          link: {
            type: entity.attributes?.field_link_type,
            url:
              entity.attributes?.field_link_type === 'int'
                ? entity.attributes?.computed_link_int
                : entity.attributes?.field_link_type === 'ext'
                ? entity.attributes?.field_link_ext.uri
                : null,
          },

          image: getImageCloudinaryImg(entity.relationships?.field_media?.data),
        })),
      }
    } catch (error) {
      console.warn('TINY_SLIDER_BLOCK', error)
    }
  }
  if (element.type === TESTIMONIAL_BLOCK) {
    try {
      return {
        type: TESTIMONIAL_BLOCK,
        id: element?.id,
        cards: getEntities(element.relationships?.field_reference.data).map((entity: any) => ({
          title: entity.attributes?.field_title,
          name: entity.attributes.field_name,
          position: entity.attributes.field_position,
          date: entity.attributes.field_date,
          copy: entity?.attributes?.field_wysiwyg?.processed,
          image: getImageCloudinaryImg(entity.relationships?.field_media?.data),
        })),
      }
    } catch (error) {
      console.warn('TESTIMONIAL_BLOCK', error)
    }
  }
  if (element.type === BIG_ARGUMENT_BLOCK) {
    try {
      return {
        type: BIG_ARGUMENT_BLOCK,
        id: element?.id,
        title: element.attributes?.field_title,
        subtitle: element.attributes?.field_subtitle,
        copy: element.attributes?.field_wysiwyg?.processed,
        image: getImageCloudinaryImg(element.relationships?.field_media?.data),
        cards: getEntities(element.relationships?.field_reference.data).map((entity: any) => ({
          title: entity.attributes?.field_title,
          copy: entity?.attributes?.field_wysiwyg?.processed,
          link: getLink(entity?.relationships?.field_link?.data),
        })),
      }
    } catch (error) {
      console.warn('BIG_ARGUMENT_BLOCK', error)
    }
  }
  if (element.type === FAQ_BLOCK) {
    try {
      const blocks = getEntities(element.relationships?.field_entities.data).map((item: any) =>
        getEntities(item.relationships?.field_entities.data),
      )?.[0]

      return {
        type: FAQ_BLOCK,
        id: element?.id,
        firstExpanded: element?.attributes?.field_first_expanded,
        cards: blocks.map((block: any) => ({
          id: block?.id,
          title: block?.attributes?.title,
          cta: {
            title: block?.attributes?.field_title,
            subtitle: block?.attributes?.field_subtitle,
            image: getImageCloudinary(
              block?.relationships?.field_media?.data,
              {
                transformations: {
                  format: 'webp',
                  resize: {
                    type: 'fill',
                    height: 1024,
                  },
                },
              },
              {},
              { cloudname: config.cloudname },
            ),
            link: getLink(block?.relationships?.field_link?.data),
            copy: block?.attributes?.field_wysiwyg?.processed,
          },
          copy: block?.attributes?.body?.processed,
          linkVisible: block?.attributes?.field_display_node_link,
          link:
            block?.attributes?.path?.alias || `/node/${block?.attributes?.drupal_internal__nid}`,
        })),
      }
    } catch (error) {
      console.warn('FAQ_BLOCK', error)
    }
  }
  if (element.type === COMPACT_NEWS_BLOCK) {
    try {
      return {
        type: COMPACT_NEWS_BLOCK,
        id: element?.id,
        title: element?.attributes?.field_title,
        subtitle: element?.attributes?.field_subtitle,
        link: getLink(element?.relationships?.field_link?.data),
        cards: getEntities(element?.relationships?.field_entities?.data).map(
          (entity: any, index: number) => ({
            id: entity?.id,
            title: entity?.attributes?.title,
            copy: entity?.attributes?.body?.processed,
            link: {
              url: entity?.attributes?.path?.alias,
              title: t('read-more'),
            },
            image: getImageCloudinary(
              entity.relationships?.field_node_media?.data,
              {
                transformations: {
                  format: 'webp',
                  resize: {
                    type: 'fill',
                    height: index === 0 ? 1024 : 300,
                  },
                },
              },
              {},
              { cloudname: config.cloudname },
            ),
          }),
        ),
      }
    } catch (error) {
      console.warn('COMPACT_NEWS_BLOCK', error)
    }
  }
  if (element.type === PODCAST_BLOCK) {
    try {
      return {
        type: PODCAST_BLOCK,
        id: element?.id,
        title: element?.attributes?.field_title,
        subtitle: element?.attributes?.field_subtitle,
        copy: element?.attributes?.field_wysiwyg?.processed,
        podcasts: getEntities(element.relationships?.field_reference.data).map(
          (item: any) => item?.attributes?.field_spotify_id,
        ),
        link: getLink(element?.relationships?.field_link?.data),
      }
    } catch (error) {
      console.warn('PODCAST_BLOCK', error)
    }
  }
  if (element.type === TABS_BLOCK) {
    try {
      return {
        type: TABS_BLOCK,
        id: element?.id,
        title: element?.attributes?.field_title,
        subtitle: element?.attributes?.field_subtitle,
        copy: element?.attributes?.field_wysiwyg?.processed,
        media: getMedia(element?.relationships?.field_media?.data, { cloudname: config.cloudname }),
        tabs: getEntities(element.relationships?.field_reference.data).map((item: any) => ({
          tabLabel: item?.attributes?.field_title,
          title: item?.attributes?.field_subtitle,
          copy: item?.attributes?.field_wysiwyg?.processed,
          link: getLink(item?.relationships?.field_link?.data),
        })),
      }
    } catch (error) {
      console.warn('TABS_BLOCK', error)
    }
  }
  if (element.type === BIG_CTA_BLOCK) {
    try {
      return {
        type: BIG_CTA_BLOCK,
        id: element?.id,
        cards: getEntities(element.relationships?.field_reference.data).map((item: any) => {
          return {
            id: item?.id,
            title: item?.attributes?.field_title,
            subtitle: item?.attributes?.field_subtitle,
            copyDesktop: item?.attributes?.field_wysiwyg?.processed,
            copyMobile: item?.attributes?.field_wysiwyg_short?.processed,
            link: getLink(item?.relationships?.field_link?.data),
            image: getImageCloudinaryImg(item?.relationships?.field_media?.data),
            hasFormComponent: item?.attributes?.field_form_type === 'component',
            formComponentId: item?.attributes?.field_form_component,
          }
        }),
      }
    } catch (error) {
      console.warn('BIG_CTA_BLOCK', error)
    }
  }
  if (element.type === ON_THIS_PAGE_BLOCK) {
    try {
      return {
        type: ON_THIS_PAGE_BLOCK,
        id: element?.id,
        title: element?.attributes?.field_title,
        subtitle: element?.attributes?.field_subtitle,
        image: getImageCloudinary(
          element?.relationships?.field_media?.data,
          {
            transformations: {
              format: 'webp',
              resize: {
                type: 'fill',
                height: 800,
                width: 800,
              },
            },
          },
          {},
          { cloudname: config.cloudname },
        ),
        links: store?.included
          .filter((item: any) => blocksForOnThisPage.includes(item.type))
          .map((el: any) => ({
            id: el.id,
            title: el.attributes.field_anchor_link_name || el.attributes?.field_title,
            blockTitle: el.attributes.field_title || '',
            anchorTitle: el.attributes.field_anchor_link_name || '',
          }))
          .filter(item => item.title !== undefined)
          .filter(item => item.title !== null),
      }
    } catch (error) {
      console.warn('ON_THIS_PAGE_BLOCK', error)
    }
  }
  if (element.type === CONTENT_HTML_BLOCK) {
    try {
      return {
        type: CONTENT_HTML_BLOCK,
        id: element?.id,
        html: element?.attributes?.field_wysiwyg?.value,
      }
    } catch (error) {
      console.warn('CONTENT_HTML_BLOCK', error)
    }
  }
  if (element.type === CONTENT_TEXT_BLOCK) {
    try {
      return {
        type: CONTENT_TEXT_BLOCK,
        id: element?.id,
        copy: element.attributes?.field_wysiwyg?.processed,
        link: getLink(element?.relationships?.field_link?.data),
        linkType: element?.attributes?.field_link_appearance,
      }
    } catch (error) {
      console.warn('CONTENT_TEXT_BLOCK', error)
    }
  }
  if (element.type === SEARCH_STRINGS_BLOCK) {
    try {
      return {
        type: SEARCH_STRINGS_BLOCK,
        id: element?.id,
        cards: getEntities(element.relationships?.field_reference.data).map((entity: any) => ({
          title: entity?.attributes?.field_title,
          strings: entity?.attributes?.field_strings || [],
        })),
      }
    } catch (error) {
      console.warn('SEARCH_STRINGS_BLOCK', error)
    }
  }
  if (element.type === LIST_AUTO_BLOCK) {
    try {
      const cardsData = JSON.parse(element.attributes.computed_list) || []
      return {
        type: LIST_AUTO_BLOCK,
        id: element?.id,
        title: element.attributes?.field_title,
        subtitle: element.attributes?.field_subtitle,
        copy: element?.attributes?.field_wysiwyg?.processed,
        link: getLink(element?.relationships?.field_link?.data),
        cards: cardsData.map((card: any) => ({
          id: card?.data?.id,
          title: card?.data?.attributes?.title,
          copy: card?.data?.attributes?.body?.processed,
          link: {
            url: card?.data?.attributes?.path?.alias,
            title: t('read-more'),
          },
          image: card?.data?.relationships?.field_node_media?.data
            ? getImageCloudinary(
                card?.data?.relationships?.field_node_media?.data,
                {
                  transformations: {
                    format: 'webp',
                    resize: {
                      type: 'fill',
                      height: 600,
                    },
                  },
                },
                card?.included,
                { cloudname: config.cloudname },
              )
            : proxyOriginalImage({
                url: getImageOriginal(
                  card?.data?.relationships?.field_image?.data,
                  card?.included,
                  config.cmsBasePath,
                ),
                cloudinaryOptions: {
                  transformations: {
                    format: 'webp',
                    resize: {
                      type: 'fill',
                      height: 700,
                    },
                  },
                },
                config: { cloudname: config.cloudname, cmsBasePath: config.cmsBasePath },
              }),
          /* getImageOriginal(
              card?.data?.relationships?.field_image?.data,
              card?.included,
              config.cmsBasePath,
            ), */
        })),
      }
    } catch (error) {
      console.warn('LIST_AUTO_BLOCK', error)
    }
  }
  if (element.type === PETITION_BLOCK) {
    try {
      return {
        type: PETITION_BLOCK,
        id: element.id,
        formId: element?.attributes?.field_form_petition,
      }
    } catch (error) {
      console.warn('PETITION_BLOCK', error)
    }
  }
  if (element.type === EXPANDABLE_BLOCK) {
    try {
      return {
        type: EXPANDABLE_BLOCK,
        id: element.id,
        cards: getEntities(element?.relationships?.field_reference?.data).map((entity: any) => ({
          title: `${t('sources')}:`,
          copy: entity?.attributes?.field_wysiwyg?.processed,
        })),
      }
    } catch (error) {
      console.warn('EXPANDABLE_BLOCK', error)
    }
  }
  if (element.type === TITLE_TEXT_BLOCK) {
    try {
      return {
        type: TITLE_TEXT_BLOCK,
        id: element?.id,
        cards: getEntities(element?.relationships?.field_reference?.data).map((entity: any) => ({
          title: entity.attributes?.field_title,
          subtitle: entity.attributes?.field_subtitle,
          copy: entity.attributes?.field_wysiwyg?.processed,
          link: getLink(entity.relationships?.field_link?.data),
          entity,
        })),
      }
    } catch (error) {
      console.warn('TITLE_TEXT_BLOCK', error)
    }
  }

  if (element.type === SMALL_IMAGE_GRID_BLOCK) {
    try {
      return {
        type: SMALL_IMAGE_GRID_BLOCK,
        id: element?.id,
        firstExpanded: element?.attributes?.field_first_expanded,
        link: getLink(element.relationships?.field_link?.data),
        cards: getEntities(element.relationships?.field_reference.data).map((entity: any) => ({
          id: entity.id,
          title: entity.attributes.field_title,
          cardType: entity.attributes?.field_cardtype,
          sub: getEntities(entity.relationships.field_reference.data).map((item: any) => ({
            cardTitle: item.attributes?.field_title,
            links: getLinks(item?.relationships?.field_link?.data),
            image: getImageCloudinary(
              item?.relationships?.field_media?.data,
              {
                transformations: {
                  resize: {
                    type: 'limit',
                    height: 600,
                  },
                },
              },
              {},
              { cloudname: config.cloudname },
            ),
          })),
        })),
      }
    } catch (error) {
      console.warn('SMALL_IMAGE_GRID_BLOCK', error)
    }
  }

  return []
}
