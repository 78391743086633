import routerOptions0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/render/project/src/apps/refresh/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}